export default [
    {
      title: 'Client Dashboard',
      route: 'client-dashboard',
      icon: 'HomeIcon',
      adminAuth: false,
      clientAuth: true,
    },
    {
      title: 'Event Dashboard',
      route: 'evento-dashboard',
      icon: 'CheckSquareIcon',
      adminAuth: true,
      clientAuth: false,
    },
    {
      title: 'Clients Dashboard',
      route: 'clients-dashboard',
      icon: 'SettingsIcon',
      adminAuth: true,
      clientAuth: false,
    },
  ]
  