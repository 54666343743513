var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"kt_aside",staticClass:"aside aside-left d-flex flex-column",attrs:{"id":"kt_aside"}},[_c('div',{staticClass:"aside-brand d-flex flex-column align-items-center flex-column-auto pt-5 pt-lg-18 pb-10"},[_c('div',{staticClass:"btn p-0 symbol symbol-60 symbol-light-primary",attrs:{"id":"kt_quick_user_toggle"}},[_c('div',{staticClass:"symbol-label"},[_c('inline-svg',{staticClass:"h-75 align-self-end",attrs:{"alt":"Logo","src":"media/svg/avatars/001-boy.svg"}})],1)])]),_c('div',{staticClass:"aside-nav d-flex flex-column align-items-center flex-column-fluid pb-10"},[_c('ul',{staticClass:"nav flex-column"},_vm._l((_vm.items),function(item){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissionNav(item)),expression:"permissionNav(item)"}],key:item.title,attrs:{"to":item.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:(("" + (item.title))),expression:"`${item.title}`",modifiers:{"hover":true,"right":true}}],staticClass:"nav-item mb-5"},[_c('a',{staticClass:"nav-link btn btn-icon btn-clean btn-lg ",class:[
              isActive && 'active',
              isExactActive && 'active' ],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-xxl"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Layout/Layout-4-blocks.svg"}})],1)])])]}}],null,true)})}),1)]),_c('div',{staticClass:"aside-footer d-flex flex-column align-items-center flex-column-auto py-8"})])}
var staticRenderFns = []

export { render, staticRenderFns }